<template>
  <el-container style="background-color: #FFFFFF">
    <div class="trainLeft">
      <head-layout head-title="培训流程" :head-btn-options="stepBtnOptions"></head-layout>
      <div class="trainStep">
        <el-steps :active="active" direction="vertical" finish-status="success">
          <el-step @click.native="active = 0" title="扫码签到"></el-step>
          <el-step @click.native="stepOne" title="开始培训"></el-step>
          <el-step @click.native="stepTwo" title="结束培训"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="content">
      <head-layout :head-btn-options="headBtnOptions" head-title="现场培训" @head-last="lastStep" @head-next="next"
                   @head-exam="personnelExamination" @head-end="EndTraining"
      >
      </head-layout>
      <sign ref="sign" v-show="active == 0" :row="rowSign"></sign>
      <startTrain ref="startTrain" v-show="active == 1"></startTrain>
      <finishTrain ref="finishTrain" v-show="active == 2"></finishTrain>
    </div>
    <!--    <div class="bottomBtn">-->
    <!--      <el-button @click="lastStep" style="width: 120px;height: 42px" type="primary" v-if="this.active != 0">上一步</el-button>-->
    <!--      <el-button @click="next" style="width: 120px;height: 42px" type="primary" v-if="this.active != 2">下一步</el-button>-->
    <!--      <el-button @click="personnelExamination" type="primary" v-if="this.active == 2">未通过人员重新考试</el-button>-->
    <!--      <el-button @click="EndTraining" v-if="this.active == 2">结束培训</el-button>-->
    <!--    </div>-->
  </el-container>
</template>
<script>
import {nowTrainUpdate} from "@/api/onTheJobTraining/training"
import sign from "@/views/business/training/onSiteTraining/sign.vue";
import startTrain from "@/views/business/training/onSiteTraining/startTrain.vue";
import finishTrain from "@/views/business/training/onSiteTraining/finishTrain.vue";
import website from "@/config/website";
import {removeEdu} from "@/api/training/examinationPaper";

export default {

  data() {
    return {
      siteTrainingId: "",
      active: 0,
      webSocketObject: null,
      webSocketInitExamObject: null,
      number: 0,
      id: "",
      rowSign: '',
      websocketType: "SignIn",//签到编码
      webSocketInitExamType: "exam",//考试编码
      intervalId: "",//定时任务
      stepBtnOptions:[
        {
          label: '上一步',
          emit: "head-last",
        }
      ]
    };
  },
  computed: {
    refresh() {
      return this.$store.state.common.refresh
    },
    headBtnOptions() {
      let result = [];
      if (this.active != 0) {
        result.push({
          label: '上一步',
          emit: "head-last",
        })
      }
      if (this.active != 2) {
        result.push({
          label: '下一步',
          emit: "head-next",
        })
      }
      if (this.active == 2) {
        result.push({
          label: '未通过人员重新考试',
          emit: "head-exam",
        }, {
          label: '结束培训',
          emit: "head-end",
        })
      }
      return result
    }
  },
  components: {
    sign,
    startTrain,
    finishTrain
  },
  methods: {
    lastStep() {
      this.active--;
      if (this.active == 1) {
        this.$nextTick(() => {
          this.$refs.startTrain.id = this.id;
          this.$refs.startTrain.taskId = this.siteTrainingId;
          this.$refs.startTrain.trainGroupScenDetailList();
          this.$refs.sign.saveNumber();
          //初始化WebSocket 考试
          this.webSocketInitExam()
        })
      }
      if (this.active == 2) {
        this.$nextTick(() => {
          this.$refs.finishTrain.siteTrainingId = this.siteTrainingId;
          this.$refs.finishTrain.recordingId = this.id;
          this.$refs.finishTrain.qualificationRate();
        })
      }
    },
    stepOne() {
      this.active = 1;
      this.$nextTick(() => {
        this.$refs.startTrain.id = this.id;
        this.$refs.startTrain.taskId = this.siteTrainingId;
        this.$refs.startTrain.trainGroupScenDetailList();
        this.$refs.sign.saveNumber();
        //初始化WebSocket 考试
        this.webSocketInitExam()
      })
    },
    stepTwo() {
      this.active = 2;
      this.$nextTick(() => {
        this.$refs.finishTrain.siteTrainingId = this.siteTrainingId;
        this.$refs.finishTrain.recordingId = this.id;
        this.$refs.finishTrain.qualificationRate();
      })
    },
    //下一步
    next() {
      this.active++;
      if (this.active == 1) {
        this.$nextTick(() => {
          this.$refs.startTrain.id = this.id;
          this.$refs.startTrain.taskId = this.siteTrainingId;
          this.$refs.startTrain.trainGroupScenDetailList();
          this.$refs.sign.saveNumber();
          //初始化WebSocket 考试
          this.webSocketInitExam()
        })
      }
      if (this.active == 2) {
        this.$nextTick(() => {
          this.$refs.finishTrain.siteTrainingId = this.siteTrainingId;
          this.$refs.finishTrain.recordingId = this.id;
          this.$refs.finishTrain.qualificationRate();
        })
      }
    },
    //签到 webSocket
    webSocketInit() {
      const webSocketUrl = process.env.VUE_APP_BASE_TRAINWEBSOCKET + this.siteTrainingId + "/" + this.websocketType
      this.webSocketObject = new WebSocket(webSocketUrl);
      this.webSocketObject.onopen = this.webSocketOnOpen
      this.webSocketObject.onmessage = this.webSocketOnMessage
      this.webSocketObject.onerror = this.webSocketOnError
      this.webSocketObject.onClose = this.webSocketOnClose
    },
    webSocketOnOpen(e) {
      // 定期发送心跳消息
      let that = this
      that.intervalId = setInterval(function () {
        that.webSocketOnSend()
        console.log('来自服务端的消息->', e)
        console.clear()
      }, 5000); // 30秒发送一次心跳消息
    },
    webSocketOnMessage(e) {
      console.log('来自服务端的消息->', e)
      if (e.data != "ping") {
        var parse = JSON.parse(e.data);
        this.$refs.sign.number = parse.size;
        this.number = parse.size;
        this.$refs.sign.name = parse.name;
        this.$refs.sign.id = this.id
      } else if (e.data == "ping") {
        console.log("连接正常")
      } else {
        this.$message.warning("连接超时!")
      }
    },
    webSocketOnSend() {
      this.webSocketObject.send('ping'); // 发送心跳消息
    },
    webSocketOnError(e) {
      console.log('与服务端连接异常->', e)
    },
    webSocketOnClose(e) {
      console.log('与服务端连接关闭->', e)
    },
    personnelExamination() {
      this.active = 1;
    },
    //考试 webSocket
    webSocketInitExam() {
      clearInterval(this.intervalId)
      //停止签到的定时任务
      console.log("停止签到的定时任务")
      const webSocketUrl = process.env.VUE_APP_BASE_TRAINWEBSOCKET + this.siteTrainingId + "/" + this.webSocketInitExamType
      this.webSocketInitExamObject = new WebSocket(webSocketUrl);
      this.webSocketInitExamObject.onopen = this.webSocketInitExamOnOpen
      this.webSocketInitExamObject.onmessage = this.webSocketInitExamOnMessage
      this.webSocketInitExamObject.onerror = this.webSocketInitExamOnError
      this.webSocketInitExamObject.onclose = this.webSocketInitExamOnClose
    },
    webSocketInitExamOnOpen(e) {
      let that = this
      console.log("启用考试的定时任务")
      setInterval(function () {
        that.webSocketInitExamOnSend()
        console.log('来自服务端的消息->', e)
        console.clear()
      }, 5000); // 30秒发送一次心跳消息
    },
    webSocketInitExamOnMessage(e) {
      console.log('来自服务端的消息1->', e)
      if (e.data != "ping") {
        var parse = JSON.parse(e.data);
        this.$refs.finishTrain.number = parse.qualified;
        // this.$refs.finishTrain.count = parse.count;
        this.$refs.finishTrain.totalCount = parse.totalCount;
        this.$refs.finishTrain.count = (parse.totalCount - parse.count);
        this.$refs.finishTrain.names = parse.name ? parse.name.split(',') : []
      } else if (e.data == "ping") {
        console.log("连接正常")
      } else {
        this.$message.warning("连接超时!")
      }
    },
    webSocketInitExamOnSend() {
      this.webSocketObject.send('ping'); // 发送心跳消息
    },
    webSocketInitExamOnError(e) {
      console.log('与服务端连接异常1->', e)
    },
    webSocketInitExamOnClose(e) {
      console.log('与服务端连接关闭1->', e)
    },
    EndTraining() {
      if (this.$refs.finishTrain.count > 0) {
        this.$confirm('是否确认结束培训？当前未提交试卷' + this.$refs.finishTrain.count + '人 (结束培训不影响未提交试卷人成绩)', '结束培训', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          nowTrainUpdate(this.id, "finishTraining", this.siteTrainingId).then((res) => {
            this.$message.success("操作成功")
            this.$router.$avueRouter.closeTag();
            this.$store.commit('SET_REFRESH', !this.refresh)
          }).then(() => {
            setTimeout(function () {
              window.close();
            }, 1000);
          })
        })
      } else {
        nowTrainUpdate(this.id, "finishTraining", this.siteTrainingId).then((res) => {
          this.$message.success("操作成功")
          this.$router.$avueRouter.closeTag();
          this.$store.commit('SET_REFRESH', !this.refresh)
        }).then(() => {
          setTimeout(function () {
            window.close();
          }, 1000);
        })
      }
    }
  },
  mounted() {
    this.$refs.sign.initData(this.rowSign)
  },
  created() {
    var row = this.$route.query.row;
    this.rowSign = row
    var data = JSON.parse(row);
    console.log("123456--------------", data)
    this.siteTrainingId = data.siteTrainingId
    this.id = data.id
    //初始化WebSocket 签到
    this.webSocketInit()
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: calc(100% - 24px);
  padding: 0 12px 32px 12px;
  min-height: calc(100% - 32px);
  background-color: #FFFFFF;
  position: relative;
}

.trainLeft {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;

  .trainStep {
    flex-shrink: 0;
    padding: 12px;

    .el-steps {
      height: 300px;

      ::v-deep .el-step__title {
        cursor: pointer;
      }
    }
  }
}

.trainLeft ::v-deep .head-layout .headBtn {
  opacity: 0;
}

.trainLeft ::v-deep .head-layout .headBtn button {
  cursor: default;
}

//::v-deep .el-step__icon {
//  width: 50px;
//  height: 50px;
//  font-size: 25px;
//}
//
//::v-deep .el-step.is-horizontal .el-step__line {
//  top: 24px;
//}

.content {
  width: 100%;
}

.bottomBtn {
  width: 100%;
  //position: absolute;
  margin-top: 86px;
  display: flex;
  justify-content: center;
  bottom: 12px;
}
</style>
