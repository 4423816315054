<template>
  <div>
    <div class="codeImg">
      <img :src="twoDimensionalCodePicture" />
    </div>
    <div class="signPeople">
      {{ this.name }}
    </div>
    <div class="signNumber">
      <!-- 已签到人数 <span :style="{color:themeColor}">{{ number }}</span> 人 -->
      <img src="@/assets/images/trainSign.png" alt="" />
      <div class="number-item">
        <div class="number-text">{{ number }}</div>
        <div>已签到人数</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { twoDimensionalCode } from "@/api/onTheJobTraining/training";
import { saveOrUpdate } from "@/api/training/recording";

export default {
  data() {
    return {
      name: "",
      number: 0,
      twoDimensionalCodePicture: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters(["themeColor"]),
  },
  mounted() {},
  methods: {
    initData(res) {
      var data = JSON.parse(res);
      var person = {
        id: data.id,
        type: "scanCodeSignIn",
        taskId: data.siteTrainingId,
      };
      twoDimensionalCode(JSON.stringify(person)).then((res) => {
        this.twoDimensionalCodePicture =
          "data:image/png;base64," + res.data.data;
      });
    },
    saveNumber() {
      let data = {
        id: this.id,
        traineesNumber: this.number,
      };
      saveOrUpdate(data).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data, "168");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.codeImg {
  width: 260px;
  height: 260px;
  margin: 24px auto;
  background-image: url("~@/assets/images/signbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5px;
}

.codeImg img {
  width: 100%;
  height: 100%;
}

.signPeople {
  width: 50%;
  margin: 8vh auto 6vh;
  text-align: center;
  font-size: 25px;
}

.signNumber {
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  .number-item {
    font-size: 22px;
    color: #3d3d3d;
    margin-left: 20px;
    .number-text {
      font-size: 36px;
      color: #3d3d3d;
    }
  }
}
</style>
