var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticStyle: { "background-color": "#FFFFFF" } },
    [
      _c(
        "div",
        { staticClass: "trainLeft" },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "培训流程",
              "head-btn-options": _vm.stepBtnOptions,
            },
          }),
          _c(
            "div",
            { staticClass: "trainStep" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.active,
                    direction: "vertical",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: { title: "扫码签到" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.active = 0
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "开始培训" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.stepOne.apply(null, arguments)
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "结束培训" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.stepTwo.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "现场培训",
            },
            on: {
              "head-last": _vm.lastStep,
              "head-next": _vm.next,
              "head-exam": _vm.personnelExamination,
              "head-end": _vm.EndTraining,
            },
          }),
          _c("sign", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active == 0,
                expression: "active == 0",
              },
            ],
            ref: "sign",
            attrs: { row: _vm.rowSign },
          }),
          _c("startTrain", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active == 1,
                expression: "active == 1",
              },
            ],
            ref: "startTrain",
          }),
          _c("finishTrain", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active == 2,
                expression: "active == 2",
              },
            ],
            ref: "finishTrain",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }